// extracted by mini-css-extract-plugin
export var container = "style-module--container--3sJjQ";
export var wrapper = "style-module--wrapper--35Iit";
export var logoContainer = "style-module--logoContainer--1DOAF";
export var desktopContainer = "style-module--desktopContainer--tXjjO";
export var mobileContainer = "style-module--mobileContainer--c6Mgt";
export var baseline = "style-module--baseline--i1can";
export var followUsContainer = "style-module--followUsContainer--3DsPq";
export var socialIconsContainer = "style-module--socialIconsContainer--1I1Pe";
export var columnWrapper = "style-module--columnWrapper--35J0z";
export var footerElemContainer = "style-module--footerElemContainer--2mj9E";
export var bottomContainer = "style-module--bottomContainer--3IDb1";
export var linksContainer = "style-module--linksContainer--2LRXs";