import React from 'react'
import PropTypes from 'prop-types'
import 'styles/app.css'
import * as styles from './style.module.sass'

import { Link } from 'gatsby'
import Navigation from 'components/Navigation'
import Logout from 'static/logout.svg'

const Header = ({ data, isHome }) => {
  const site = data?.allGhostSettings?.edges[0]?.node
  return (
    <div>
      {/* The main header section on top of the screen */}
      <header className="site-head" style={{ ...site?.cover_image && { backgroundImage: `url(${site.cover_image})` } }}>
        <div className="container">
          <div className="site-mast">
            <div className="site-mast-left">
              <Link to="/">
                <img className="site-logo" src={site?.logo} alt={site?.title} />
              </Link>
            </div>
            <div className="site-mast-right">
              <a className={styles.redirectLink} href={`https://artelink.com`} target="_blank" rel="noopener noreferrer">
                Aller sur le site Artelink 
                <span className={styles.logoContainer}><Logout /></span>
              </a>
            </div>
          </div>
          {isHome ?
            <div className="site-banner">
              <h1 className="site-banner-title">{site?.title}</h1>
              <p className="site-banner-desc">{site?.description}</p>
            </div> :
            null}
          <nav className="site-nav">
            <div className="site-nav-left">
              {/* The navigation items as setup in Ghost */}
              <Navigation data={site?.navigation} navClass="site-navigation" />
            </div>
            <div className="site-nav-right">
              <a className="site-nav-button" href="https://artelink.com/a-propos" target="_blank">À propos</a>
            </div>
          </nav>
        </div>
      </header>
    </div>
  )
}

Header.propTypes = {
  isHome: PropTypes.bool,
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      logo: PropTypes.string,
      icon: PropTypes.string,
      cover_image: PropTypes.string,
      lang: PropTypes.string,
      navigation: PropTypes.array
    }),
  }).isRequired,
}
export default Header
