import React from 'react'
import ContentWrapper from 'components/ContentWrapper/'
import * as styles from './style.module.sass'
import 'styles/common.sass'
import LogoFull from 'static/logo-full.svg'
import Logo from 'static/logo.svg'
import Linkedin from 'static/linkedin.svg'

import Icon from 'components/Icons'
import { openPopupWidget } from 'react-calendly'

const onClick = () => openPopupWidget({ url: 'https://calendly.com/artelink' });

const FooterLink = ({ link, children }) => (
  <a href={link} target="_blank" rel='noreferrer'>
    <Icon icon="rightArrow" iconSize="small" containerSize="small" />
    {children}
  </a>
)

const dateFooter = new Date().getFullYear()

const Footer = () => {
  return (
    <>
      <div className={styles.container}>
        <ContentWrapper>
          <div className={`${styles.wrapper} columns`}>
            <div className="column is-two-fifths-desktop">
              <div className={styles.logoContainer}>
                <div className={styles.mobileContainer}>
                  <Logo />
                </div>
                <div className={styles.desktopContainer}>
                  <LogoFull />
                </div>
                <h3 className={styles.baseline}>
                  Artelink est la première communauté de freelance hautement qualifiés dans l'ingénierie
                </h3>
              </div>

              <div className={styles.followUsContainer}>
                <p>Suivez-nous !</p>
                <a className={styles.socialIconsContainer} href="https://www.linkedin.com/company/artelink" target="_blank" rel="noreferrer">
                  <Linkedin />
                </a>
              </div>
            </div>

            <div className="column">
              <div className={`columns ${styles.linksContainer}`}>
                <div className="column">
                  <div className={styles.columnWrapper}>
                    <h4>ENTREPRISE</h4>
                    <FooterLink link="https://artelink.com/">Accueil entreprise</FooterLink>
                    <FooterLink link="https://artelink.com/fonctionnement">Fonctionnement </FooterLink>
                    <FooterLink link="https://artelink.com/selection">Qualification des profils </FooterLink>
                    <div className={styles.footerElemContainer}>
                      <Icon icon="rightArrow" iconSize="small" containerSize="small" />
                      <a onClick={onClick}>Prendre rendez vous </a>

                    </div>
                  </div>
                  <div className={styles.columnWrapper}>
                    <h4>FREELANCE</h4>
                    <FooterLink link="https://artelink.com/freelance">Accueil freelance </FooterLink>
                    <FooterLink link="https://artelink.com/comment-ca-marche">Fonctionnement </FooterLink>
                    <FooterLink link="https://app.artelink.com/signup?pType=FREELANCE">S'inscrire</FooterLink>
                  </div>
                </div>
                <div className="column">
                  <div className={styles.columnWrapper}>
                    <h4>ARTELINK</h4>
                    <FooterLink link="https://artelink.com/a-propos">Qui sommes-nous ? </FooterLink>
                    <FooterLink link="https://artelink.com/contact">Nous contacter</FooterLink>
                    <FooterLink link="https://support.artelink.com">Notre F.A.Q</FooterLink>
                    <FooterLink link="mailto:contact@artelink.com">Nous rejoindre</FooterLink>
                    <FooterLink link="https://artelink.com/conditions-generales">Nos conditions générales</FooterLink>
                    <FooterLink link="https://artelink.com/mentions-légales">Nos mentions légales</FooterLink>
                    <FooterLink link="https://artelink.com/rgpd">Vos données personnelles</FooterLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
      <div className={styles.bottomContainer}>
        <ContentWrapper>
          <p>© {dateFooter} Artelink - Tous droits réservés</p>
        </ContentWrapper>
      </div>

    </>
  )
}

export default Footer