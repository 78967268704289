import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Footer from './Footer/'
import Header from './Header/'
import DeploymentButton from 'components/DeploymentButton'
// Styles
import '../styles/app.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, bodyClass, children, isHome }) => {
  const site = data.allGhostSettings.edges[0].node
  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <body className={bodyClass} />
      </Helmet>

      <div className="viewport">
        <div>

          <Header data={data} site={site} isHome={isHome} />
          <main className="site-main">
            {/* All the main content gets inserted here, index.js, post.js */}
            {process.env.GATSBY_BLOG_ENV === 'staging' && <DeploymentButton />}
            {children}
          </main>
        </div>
        {/* The footer at the very bottom of the screen */}
        <Footer />
      </div>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  isHome: PropTypes.bool,
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
            query ghostSettingsAndGhostSettings  {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
            }
        `}
    render={data => <DefaultLayout data={data} {...props} />}
  />
)

export default DefaultLayoutSettingsQuery
